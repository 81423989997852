<template>
	<div class="treatmenttarget">
		<el-container>
			<el-row>
				<el-col :span="6">治疗目标</el-col>
				<el-col :span="18">
					<div id="div1" style="text-align: left;"></div>
					<button @click="goNext">下一步</button>
				</el-col>
			</el-row>
		</el-container>
	</div>
</template>

<script>
	//导入富文本编辑器
	import E from 'wangeditor'   
	export default{
		data:function(){
			return{
				editor:'',
				treatment:''
			}
		},
		methods:{
			//下一步
			goNext(){
				//将富文本编辑器中内容给treatment赋值
				this.treatment=this.editor.txt.text();
				console.log(this.treatment);
				//去上传X光片
			}
		},
		mounted:function(){
		     //创建Editor对象
		     this.editor=new E("#div1");
			 //配置富文本编辑器的高
			this.editor.config.height=100;
			 
			 // 配置 onchange 回调函数
			 this.editor.config.onchange = function (newHtml) {
				 console.log();
			   console.log("change 之后最新的 html", newHtml);
			 };
			 // 配置触发 onchange 的时间频率，默认为 200ms
			 this.editor.config.onchangeTimeout = 500; // 修改为 500ms			 
			 //创建
			 this.editor.create();
		}
	}
</script>

<style scoped="scoped">
	#div1{
		height: 31.25rem;
		width: 31.25rem;
		border: 1px solid red;
	}
</style>
